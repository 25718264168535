import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import { namespaces } from '@shared/constants';

interface EventDetails {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
    location: string;
    meetingUrl: string;
    timezone: string;
}

interface DownloadEventProps {
    eventDetails: EventDetails;
}

export const DownloadEvent: FC<DownloadEventProps> = ({ eventDetails }) => {
    const { t } = useTranslation(namespaces.features.events);
    const handleDownload = () => {
        const { title, description, startDate, endDate, location, meetingUrl, timezone } = eventDetails;

        const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Your Organization//Your App//EN
BEGIN:VEVENT
UID:${generateUID()}
SUMMARY:${title}
DESCRIPTION:${description}\n${meetingUrl}
DTSTART:${formatDateToICS(startDate)}
DTEND:${formatDateToICS(endDate)}
TIMEZONE:${timezone}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;

        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${title}.ics`;
        a.click();
        URL.revokeObjectURL(url);
    };

    const formatDateToICS = (date: string): string => {
        const dt = new Date(date);
        return dt.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
    };

    const generateUID = () => {
        return 'uid-' + Math.random().toString(36).substring(2, 9);
    };

    return (
        <button
            onClick={handleDownload}
            className="flex items-center rounded bg-blue-850 px-3 py-2 text-sm font-normal text-white"
        >
            <CalendarIcon className="mr-1" />
            {t('card.calendar-event')}
        </button>
    );
};
