export const primaryColor = '#00A0DF';

export const secondaryColor = '#97CAEB';

export const whiteColor = '#FFF';

export const blackColor = '#000';
export const black800Color = '#0D0D0D';

export const blue50Color = '#EBF9FF';
export const blue100Color = '#DFF1FB';
export const blue400Color = '#7EC3E9';
export const blue900Color = '#0065A1';
export const blueAccessibleColor = '#007CBA';

export const greyNeutralColor = '#ECECEC';

export const accessibleActiveColor = '#00818F';

export const purpleColor = '#B14FC5';

export const tealColor = '#00AFAA';

export const yellowColor = '#FFC600';

export const greyColor = '#B2B2B2';
export const grey500Color = '#9D9D9D';
