import { GuidelineData, guidelineListParser } from './domain';

import { agent } from '@shared/api';

const apiBaseRoutes = {
    guidelinesAll: '/api/guidelines/all',
};

export const getGuidelines = async () => {
    const url = apiBaseRoutes.guidelinesAll;
    const response = await agent.get<GuidelineData[]>(url);

    return response ? guidelineListParser.parseAsync(response) : null;
};
