import { z } from 'zod';

export interface GetReportListParams {
    tags?: string[];
    itStream?: string[];
    leads?: string[];
    partners?: string[];
    marketsAndRegions?: string[];
    status?: string[];
}

export enum InitiativeStatus {
    ConceptValidation = 1,
    Pilot = 2,
    Industrialization = 3,
    ProductionAtScale = 4,
}

const tagsParser = z.object({
    key: z.string(),
    value: z.string().optional(),
});

const contactParser = z.object({
    firstName: z.string(),
    lastName: z.string().optional(),
});

const contactDetailParser = z.object({
    firstName: z.string(),
    lastName: z.string().optional(),
    email: z.string().optional(),
    photo: z.string().optional(),
});

const itSteamParser = z
    .object({
        key: z.string(),
        value: z.string().optional(),
    })
    .optional();

const partnerParser = z.object({
    key: z.string(),
    value: z.string().optional(),
});

export const reportDetailParser = z.object({
    bloomflowURL: z.string(),
    horizons: z.array(z.string()).optional(),
    businessValue: z.string().optional(),
    status: z.string(),
    id: z.string(),
    name: z.string(),
    shortDescription: z.string(),
    contacts: z.array(contactDetailParser),
    tags: z.array(tagsParser),
    initiativeStatus: z.number(),
    itStream: itSteamParser,
    partners: z.array(partnerParser),
});

export const reportParser = z.object({
    name: z.string(),
    shortDescription: z.string(),
    contacts: z.array(contactParser),
    tags: z.array(tagsParser),
    initiativeStatus: z.number(),
    itStream: itSteamParser,
    partners: z.array(partnerParser),
    id: z.string(),
    status: z.string(),
});

export const reportsValidator = z.object({
    reports: z.array(reportParser),
});

export const reportDetailValidator = z.object({
    report: reportDetailParser,
});

export type Reports = z.infer<typeof reportsValidator>;
export type ReportDetail = z.infer<typeof reportDetailValidator>;
export type ReportDetailType = z.infer<typeof reportDetailParser>;
export type Report = z.infer<typeof reportParser>;
