import {
    TopContentItem,
    TopContentPageResponse,
    topContentParser,
    DiscoveryCardsResponse,
    discoveryCardListParser,
} from './domain';

import { agent } from '@shared/api';

const apiRoutes = {
    TopContentPage: '/api/topcontent',
    DicoveryCards: '/api/discoverycards',
};

export const getDiscoveryCards = async () => {
    const response = await agent.get<DiscoveryCardsResponse>(apiRoutes.DicoveryCards);

    return response ? discoveryCardListParser.parseAsync(response) : null;
};

export const getTopContentPage = async (): Promise<TopContentItem[]> => {
    const response = await agent.get<TopContentPageResponse>(apiRoutes.TopContentPage);
    return topContentParser.parseAsync(response);
};
