import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { namespaces } from '@shared/constants';
import { formatNumber } from '@shared/formatting/formatNumber';

type Props = {
    aggregatedData: {
        currency: string;
        totalAllocated: number;
        totalConsumed: number;
        totalProjects: number;
    };
};

const ProjectsKpis = ({ aggregatedData }: Props) => {
    const { t: coInnovationT } = useTranslation(namespaces.features.coInnovationFunds);
    const { currency, totalAllocated, totalConsumed, totalProjects } = aggregatedData;
    const sections = [
        {
            title: coInnovationT('coInnovationFunds.detail.projectsDetails.kpi.totalProjects'),
            value: totalProjects,
            titleTestId: 'totalProjects-title',
            valueTestId: 'totalProjects-value',
        },
        {
            title: coInnovationT('coInnovationFunds.detail.projectsDetails.kpi.totalAllocated'),
            value: `${formatNumber(totalAllocated)} ${currency}`,
            titleTestId: 'totalAllocated-title',
            valueTestId: 'totalAllocated-value',
        },
        {
            title: coInnovationT('coInnovationFunds.detail.projectsDetails.kpi.totalConsumed'),
            value: `${formatNumber(totalConsumed)} ${currency}`,
            titleTestId: 'totalConsumed-title',
            valueTestId: 'totalConsumed-value',
        },
    ];

    return (
        <div
            className="mb-4 flex w-full flex-wrap gap-3 md:flex-nowrap md:justify-between"
            aria-label="Fund Key Performance Indicators"
            data-testid="projectsKpis"
        >
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={classNames(
                        'flex flex-grow justify-between rounded-lg border-2 border-blue-100 py-4 px-5 text-left md:flex-auto',
                        {
                            'bg-blue-50': index === 0,
                        }
                    )}
                >
                    <div>
                        <p className="mb-[6px] text-base font-normal" data-testid={section.titleTestId}>
                            {section.title}
                        </p>
                        <p data-testid={section.valueTestId} className="text-2xl font-light">
                            {section.value}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectsKpis;
