import { useEffect } from 'react';

import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import './assets/styles/global.css';
import favicon from '../public/favicon.ico';

import './assets/styles/scrollbar.scss';
import './assets/styles/typography.css';
import { ClientSideNavigation } from './ClientSideNavigation';
import { Layout } from './layouts';
import { LazyErrorPage } from './pages';
import { ApplicationRoutes } from './router';

import { namespaces } from '@shared/constants';
import { noop } from '@shared/generic-functions';
import { Settings } from '@shared/settings';
import { useStore } from '@store';

const queryClient = (cacheTime: number) =>
    new QueryClient({
        defaultOptions: {
            queries: {
                retry: 0,
                suspense: true,
                refetchOnWindowFocus: false,
                staleTime: cacheTime,
                useErrorBoundary: true,
            },
        },
    });

type Props = {
    publicClientApplication: IPublicClientApplication;
    settings: Settings;
    appInsightsPlugin: ReactPlugin;
};

const App = ({
    publicClientApplication,
    settings: { productCatalogUrl, serviceNowUrl, cacheTime, serviceNowSupportUrl },
    appInsightsPlugin,
}: Props) => {
    const setProductCatalogUrl = useStore(state => state.productCatalog.setUrl);
    const setServiceNowCreateUrl = useStore(state => state.serviceNow.setCreateUrl);
    const setServiceNowSupportUrl = useStore(state => state.serviceNow.setSupportUrl);

    const { t } = useTranslation(namespaces.common);

    useEffect(() => {
        setProductCatalogUrl(productCatalogUrl);
    }, [setProductCatalogUrl, productCatalogUrl]);

    useEffect(() => {
        setServiceNowCreateUrl(serviceNowUrl);
    }, [setServiceNowCreateUrl, serviceNowUrl]);

    useEffect(() => {
        setServiceNowSupportUrl(serviceNowSupportUrl);
    }, [setServiceNowSupportUrl, serviceNowSupportUrl]);

    useEffect(() => {
        if (document.getElementById('WalkMe') === null) {
            const walkmeScript = document.createElement('script');
            walkmeScript.type = 'text/javascript';
            walkmeScript.id = 'WalkMe';
            walkmeScript.async = true;
            walkmeScript.src = import.meta.env.VITE_WALKME_URL ?? '';
            document.body.appendChild(walkmeScript);

            (window as any)._walkmeConfig = { smartLoad: true };

            return () => {
                document.body.removeChild(walkmeScript);
            };
        }
        return () => {};
    }, []);
    return (
        <>
            <Helmet>
                <title>{t('header.title')}</title>
                <link rel="icon" type="image/png" href={favicon} />
            </Helmet>
            <ErrorBoundary FallbackComponent={() => <LazyErrorPage />} onReset={noop}>
                <AppInsightsContext.Provider value={appInsightsPlugin}>
                    <Router>
                        <ClientSideNavigation publicClientApplication={publicClientApplication}>
                            <MsalProvider instance={publicClientApplication}>
                                <QueryClientProvider client={queryClient(cacheTime)}>
                                    <Layout>
                                        <ApplicationRoutes />
                                    </Layout>
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </QueryClientProvider>
                            </MsalProvider>
                        </ClientSideNavigation>
                    </Router>
                </AppInsightsContext.Provider>
            </ErrorBoundary>
        </>
    );
};

export { App };
