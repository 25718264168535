import { z } from 'zod';

import { emptyUUID } from '@shared/constants';

export const detailProjectParser = z.object({
    projectId: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    partnerName: z.string().min(1),
    teamName: z.string().min(1),
    projectName: z.string().min(1),
    horizonName: z.string().min(1),
    allocated: z.number().min(0),
    consumed: z.number().min(0),
    phase: z.string().min(1).max(40),
    startDate: z.string().min(1),
    serviceNowSysId: z.string().min(0).max(32),
});

export const detailAggregatedParser = z.object({
    currency: z.string().min(1),
    totalAllocated: z.number().min(0),
    totalConsumed: z.number().min(0),
    totalProjects: z.number().min(0),
});

export const coInnovationFundsDetailProjectsParser = z.object({
    totalCount: z.number().min(0),
    data: z.array(detailProjectParser),
    aggregatedData: detailAggregatedParser,
});

export type DetailProjects = z.infer<typeof coInnovationFundsDetailProjectsParser>;

export type DetailProject = z.infer<typeof detailProjectParser>;

export const coInnovationFundsDetailsSingleProjectParser = z.object({
    id: z
        .string()
        .uuid()
        .refine(validation => validation !== emptyUUID),
    phase: z.string(),
    partner: z.string(),
    consumed: z.number().min(0),
    allocated: z.number().min(0),
    team: z.string(),
    globalTeam: z.string(),
    name: z.string(),
    description: z.string(),
    horizon: z.string().min(1),
    businessValue: z.string(),
    contact: z
        .object({
            name: z.string(),
            email: z.string(),
            photo: z.string().nullish(),
        })
        .nullable(),
});

export type SingleProjectDetails = z.infer<typeof coInnovationFundsDetailsSingleProjectParser>;
