import { z } from 'zod';

export enum ContentType {
    Article = 'Article',
    Initiative = 'Initiative',
    Event = 'Event',
}

const discoveryCardParser = z.object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    url: z.string(),
    image: z.object({
        id: z.number(),
        url: z.string(),
        mime: z.string(),
        caption: z.string().optional(),
    }),
    order: z.number(),
});
export type TopContentPageResponse = {
    items: TopContentItem[];
};

export type TopContentItem = {
    title: string;
    authors?: string[];
    topics?: string[];
    contacts?: string[];
    step: string;
    status: string;
    type: ContentType;
    url: string;
};

export const topContentParser = {
    parseAsync: async (response: TopContentPageResponse): Promise<TopContentItem[]> => {
        return response.items;
    },
};
export const discoveryCardListParser = z.object({
    items: z.array(discoveryCardParser),
});

export type DiscoveryCardsResponse = z.infer<typeof discoveryCardListParser>;
export type DiscoveryCardData = z.infer<typeof discoveryCardParser>;
export const StatusType: Record<string, string> = {
    in_progress: 'In progress',
    standby: 'Standby',
    rejected: 'Rejected',
    completed: 'Completed',
    planned: 'Planned',
};

export const InitiativeStatus: Record<string, string> = {
    0: 'Concept Validation',
    1: 'Pilot',
    2: 'Industrialization',
    3: 'Production At Scale',
};
