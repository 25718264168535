import { Dispatch, SetStateAction, useCallback } from 'react';

import { GuidelineData } from './domain';
import { GuidelineMobileDetail } from './GuidelineMobileDetail';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '@assets/icons/chevronUp.svg';
import { Spinner } from '@shared/ui';

type Props = {
    guidelineListData: GuidelineData[] | null | undefined;
    selectedGuideline: GuidelineData | null;
    setSelectedGuideline: Dispatch<SetStateAction<GuidelineData | null>>;
};

export const GuidelinesMobileTable = ({ guidelineListData, selectedGuideline, setSelectedGuideline }: Props) => {
    const toggleSelectedGuideline = useCallback(
        (guidelineData: GuidelineData) => {
            if (selectedGuideline?.id === guidelineData.id) {
                setSelectedGuideline(null);
            } else {
                setSelectedGuideline(guidelineData);
            }
        },
        [selectedGuideline, setSelectedGuideline]
    );

    const SpinnerWrapper = () => (
        <div className="mt-8 flex w-full justify-center">
            <Spinner />
        </div>
    );
    return (
        <div className="mt-2 flex w-full flex-col border-2 border-blue-100">
            {!guidelineListData ? (
                <>
                    <SpinnerWrapper />
                </>
            ) : (
                <>
                    {guidelineListData?.map(x => (
                        <div
                            className="cursor-pointer border border-blue-100"
                            key={x.id}
                            onClick={() => toggleSelectedGuideline(x)}
                        >
                            {x.id === selectedGuideline?.id ? (
                                <div className="flex flex-col ">
                                    <div className="text-lg flex flex-row justify-between bg-blue-100 py-3 px-2">
                                        <div className=""> {x.title}</div>
                                        <ChevronUp />
                                    </div>
                                    <GuidelineMobileDetail guidelineData={selectedGuideline} />
                                </div>
                            ) : (
                                <div className="text-lg flex flex-row justify-between py-3  px-2">
                                    <span> {x.title}</span>
                                    <ChevronDown />
                                </div>
                            )}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};
