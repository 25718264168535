import { compressToUTF16 } from 'lz-string';

export const saveToCache = (key: string, data: any) => {
    try {
        const stringifiedData = JSON.stringify({ data, time: new Date().getTime() });
        const compressedData = compressToUTF16(stringifiedData);

        if (!compressedData) {
            throw new Error('Error al comprimir los datos');
        }

        localStorage.setItem(key, compressedData);
    } catch (error) {
        console.error('Error al guardar en el cache:', error);
    }
};
