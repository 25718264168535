import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import { ReactComponent as MailIcon } from '@assets/icons/mail.svg';
import userSvgUrl from '@assets/icons/user.svg';
import { useResizeMediaQuery } from '@features/layout';
import { ReportDetailType } from '@features/reportGenaiUseCases/domain';
import { ReportDetailTag } from '@features/reportGenaiUseCases/ReportDetailTag';
import { ReportStatusStep } from '@features/reportGenaiUseCases/ReportStatusStep';
import { ShareReport } from '@features/reportGenaiUseCases/ShareReport';
import { useGetReportDetailQuery } from '@features/reportGenaiUseCases/useGetReportDetail';
import { namespaces } from '@shared/constants';
import { Spinner } from '@shared/ui';
import { BreadCrums } from '@shared/ui/breadCrums';

interface InitiativeHeaderProps {
    report: ReportDetailType | undefined;
    isMobile: boolean;
    t: (key: string) => string;
    navigate: (path: string) => void;
}

const InitiativeHeader: FC<InitiativeHeaderProps> = ({ report, isMobile, t, navigate }) => (
    <div className="relative flex flex-col gap-3 p-5 sm:pl-0 sm:pr-0">
        <div className="flex flex-col-reverse items-start justify-between md:flex-row">
            <div className="flex flex-col gap-3.5 sm:flex-row">
                <div className="flex flex-col gap-3.5">
                    <h2 className="mr-6 flex max-w-xs flex-1 text-[31px] font-light leading-normal md:max-w-3xl md:text-3xl">
                        {report?.name}
                    </h2>
                    {isMobile ? (
                        <div className="absolute top-0 right-0 p-2">
                            <CloseIcon
                                onClick={() => navigate('/initiatives')}
                                className="stroke-blue-accessible h-6 w-6 p-[6px]"
                            />
                        </div>
                    ) : (
                        <a
                            href={report?.bloomflowURL || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-accessible flex items-center"
                        >
                            <LinkIcon className="stroke-blue-accessible" />
                            <p className="text-blue-accessible text-sm underline">{t('detail.bloomFlow')}</p>
                        </a>
                    )}
                </div>
                <div className="flex gap-2">
                    <ReportDetailTag translationKey={report?.status || ''} />
                    {isMobile && (
                        <a href={report?.bloomflowURL || ''} className="text-blue-accessible flex items-center">
                            <LinkIcon className="stroke-blue-accessible" />
                            <p className="text-blue-accessible text-sm underline">{t('detail.bloomFlow')}</p>
                        </a>
                    )}
                </div>
            </div>
            {!isMobile && (
                <div className="flex-4 hidden md:flex">
                    <ShareReport />
                </div>
            )}
        </div>
    </div>
);

interface InitiativeContactsAndPipelineProps {
    report: ReportDetailType | undefined;
    t: (key: string) => string;
}

const InitiativeContactsAndPipeline: FC<InitiativeContactsAndPipelineProps> = ({ report, t }) => (
    <div className="flex flex-col items-stretch gap-6 sm:flex-col-reverse md:flex-row">
        <ContactCard contacts={report?.contacts} t={t} />
        <PipelineCard status={report?.initiativeStatus} t={t} />
    </div>
);

const ContactCard: FC<{
    contacts?: { email?: string; firstName: string; lastName?: string; photo?: string }[];
    t: (key: string) => string;
}> = ({ contacts, t }) => (
    <div className="border-grey-neutral flex w-full flex-1 flex-col gap-3 rounded-lg border bg-white p-4">
        <h2 className="text-xl">{t('detail.contact')}</h2>
        <div className="flex flex-col gap-2">
            {contacts?.map(contact => (
                <div key={contact.email} className="flex items-center justify-between text-black transition-colors">
                    <div className="flex items-center gap-2.5">
                        <span className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border border-blue-850 bg-white">
                            <img
                                src={contact.photo ? `data:image/jpg;base64,${contact.photo}` : userSvgUrl}
                                alt="avatar image"
                            />
                        </span>
                        <span>
                            {contact.firstName} {contact.lastName}
                        </span>
                    </div>
                    {contact.email && (
                        <a role="link" href={`mailto:${contact.email}`}>
                            <MailIcon className="h-6 w-6 p-1 text-blue-900" />
                        </a>
                    )}
                </div>
            ))}
        </div>
    </div>
);

const PipelineCard: FC<{ status?: number; t: (key: string) => string }> = ({ status, t }) => (
    <div className="border-grey-neutral flex w-full flex-1 flex-col gap-3 rounded-lg border bg-white p-4">
        <h2 className="text-xl">{t('detail.pipeline')}</h2>
        <div className="flex flex-1 items-center justify-center">
            <ReportStatusStep currentStep={status || 1} />
        </div>
    </div>
);

interface InitiativeContentProps {
    report: ReportDetailType | undefined;
    isMobile: boolean;
    t: (key: string) => string;
}

const InitiativeContent: FC<InitiativeContentProps> = ({ report, isMobile, t }) => (
    <>
        <SummaryCard summary={report?.shortDescription} t={t} />
        {((report?.tags && report.tags.length > 0) || (report?.horizons && report.horizons.length > 0)) && (
            <InnovationCard tags={report?.tags} horizons={report?.horizons} t={t} />
        )}
        <EcosystemCard
            itStream={report?.itStream?.value}
            partners={report?.partners}
            businessValue={report?.businessValue}
            t={t}
        />
        {isMobile && (
            <div className="w-full">
                <ShareReport className="w-full justify-center" />
            </div>
        )}
    </>
);

const SummaryCard: FC<{ summary?: string; t: (key: string) => string }> = ({ summary, t }) => (
    <div className="border-grey-neutral flex flex-col gap-3 rounded-lg border bg-white p-4 ">
        <h2 className="text-xl">{t('detail.summary')}</h2>
        <div>{summary}</div>
    </div>
);

const InnovationCard: FC<{ tags?: { value?: string }[]; horizons?: string[]; t: (key: string) => string }> = ({
    tags,
    horizons,
    t,
}) => (
    <div className="border-grey-neutral flex h-full flex-1 flex-col gap-3 rounded-lg border bg-white p-4 ">
        <h2 className="text-xl">{t('detail.innovation')}</h2>
        <div className="flex flex-col gap-2">
            {tags && tags.length > 0 && (
                <>
                    <h2 className="font-bold">{t('detail.emergingTech')}</h2>
                    <div className="flex">
                        <div className="flex flex-wrap items-center gap-1">
                            {tags.map(({ value }, i) => (
                                <span key={i} className="rounded-3xl bg-gray-100 py-2 px-3">
                                    {value}
                                </span>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </div>
        {horizons && horizons.length > 0 && (
            <div className="flex flex-col gap-2">
                <h2 className="font-bold">{t('detail.horizon')}</h2>
                {horizons.map(horizon => (
                    <p key={horizon}>{t(`${horizon}`)}</p>
                ))}
            </div>
        )}
    </div>
);

const EcosystemCard: FC<{
    itStream?: string;
    partners?: { key: string; value?: string }[];
    businessValue?: string;
    t: (key: string) => string;
}> = ({ itStream, partners, businessValue, t }) => (
    <div className="flex h-full flex-1 flex-col gap-6">
        <div className="border-grey-neutral flex flex-col gap-3 rounded-lg border bg-white p-4">
            <h2 className="text-xl">{t('detail.ecosystem')}</h2>
            <div className="flex flex-col sm:flex-row">
                <div className="flex flex-1 flex-col gap-2 pb-2 sm:pb-0">
                    <h2 className="font-bold">{t('detail.itTeam')}</h2>
                    <p>{itStream}</p>
                </div>
                {partners && partners.length > 0 && (
                    <div className="flex flex-1 flex-col gap-2">
                        <h2 className="font-bold">{t('detail.partner')}</h2>
                        {partners.map(partner => (
                            <p key={partner.key}>{partner.value}</p>
                        ))}
                    </div>
                )}
            </div>
        </div>
        {businessValue && (
            <div className="border-grey-neutral flex flex-col gap-3 rounded-lg border bg-white p-4">
                <h2 className="text-xl">{t('detail.businessValue')}</h2>
                <p>{businessValue}</p>
            </div>
        )}
    </div>
);

const InitiativeDetailPage: FC = () => {
    const { genAiId } = useParams<{ genAiId: string }>();
    const navigate = useNavigate();
    const { data, isFetching } = useGetReportDetailQuery(genAiId || '');
    const report = data?.report;
    const { isMobile } = useResizeMediaQuery();
    const { t } = useTranslation(namespaces.features.genai);
    return (
        <section className="flex flex-grow flex-col pt-header-height" id="genai">
            {!isMobile && (
                <BreadCrums
                    breadcrums={[{ name: 'Initiatives', link: `../initiatives` }, { name: 'Initiative detail' }]}
                    isContainer={true}
                />
            )}
            {isFetching ? (
                <div className="flex h-[700px] w-full flex-grow items-center justify-center">
                    <Spinner />
                </div>
            ) : (
                <>
                    <div className="h-full w-full sm:container">
                        <InitiativeHeader report={report} isMobile={isMobile} t={t} navigate={navigate} />
                    </div>

                    <article className="sm:bg-scroll-background-color">
                        <div className="flex h-full w-full flex-col gap-6 p-4 sm:container sm:pt-6 sm:pb-6">
                            <InitiativeContactsAndPipeline report={report} t={t} />
                            <InitiativeContent report={report} isMobile={isMobile} t={t} />
                        </div>
                    </article>
                </>
            )}
        </section>
    );
};

export { InitiativeDetailPage };
