import { useMemo } from 'react';

import { ColumnDef, getCoreRowModel, PaginationState, Row, useReactTable } from '@tanstack/react-table';
import classNames from 'classnames';

import { NoDataContent } from '../noData';
import { Spinner } from '../spinner';
import { CellTypes, CustomColumn, MetaCellProps } from '../table/column';
import { TableFooter } from '../table/TableFooter';
import { InfoTooltip } from '../tooltip';

import { ReactComponent as ChevronDown } from '@assets/icons/chevronDown.svg';
import { ReactComponent as ChevronRight } from '@assets/icons/chevronRight.svg';
import { formatDate, formatNumber } from '@shared/formatting';

type Props<TData extends { [key: string]: any }> = {
    data?: TData[];
    columns: CustomColumn<TData>[];
    pivotKey: string;
    isLoading: boolean;
    idKey?: string;
    totalItems?: number | undefined;
    pagination?: PaginationState;
    paginationCallback?: (pagination: PaginationState) => void;
    firstItemIndex?: number;
    lastItemIndex?: number;
    pageCount?: number;
    paginationDetail?: boolean;
    selectedRowId?: string | null;
    setSelectedRowId?: React.Dispatch<React.SetStateAction<string | undefined>>;
    useDefaultRowHandler?: boolean;
    overrideRowHeader?: boolean;
};

type CallbackFunction = (rowId: string) => void;

const MobileTable = <TData extends { [key: string]: any }>({
    data,
    columns,
    pivotKey,
    isLoading,
    idKey = 'partnerId',
    pagination,
    paginationCallback,
    totalItems = 0,
    firstItemIndex,
    lastItemIndex,
    pageCount,
    paginationDetail,
    selectedRowId,
    setSelectedRowId,
    useDefaultRowHandler,
    overrideRowHeader = true,
}: Props<TData>) => {
    const tableColumns: ColumnDef<TData>[] = useMemo(
        () =>
            columns.map(column => ({
                ...column,
                size: column.size || undefined,
                cell: props =>
                    CellTypes[column.cellType]({
                        value: props.getValue<string>(),
                        context: props,
                        meta: column.meta,
                        actions: column.actions,
                        warningMessage: column.warningMessage,
                        alignContent: column.alignContent,
                    }),
            })),
        [columns]
    );

    const table = useReactTable({
        data: data || [],
        columns: tableColumns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            pagination: pagination,
        },
        manualPagination: !!paginationCallback,
    });

    const handleRowClick = (rowId: string, callback?: CallbackFunction) => {
        setSelectedRowId?.(prevRowId => (prevRowId === rowId ? undefined : rowId));
        callback?.(rowId);
    };

    const SpinnerWrapper = () => (
        <div className="mt-8 flex w-full justify-center">
            <Spinner />
        </div>
    );

    const NoDataWrapper = () => (
        <div className="mt-8 flex w-full justify-center">
            <NoDataContent />
        </div>
    );

    const getRowClasses = (row: TData) => {
        const yearClass = 'year' in row ? 'bg-gray-100' : 'bg-blue-100';
        return classNames('grid w-full grid-cols-2 gap-x-6 p-2', yearClass);
    };

    if (isLoading) {
        return <SpinnerWrapper />;
    }

    if (!data?.length) {
        return <NoDataWrapper />;
    }

    const formatValue = (type: string, value: any) => {
        switch (type) {
            case 'currency':
                return formatNumber(Number(value)) + ' CHF';
            case 'date':
                return formatDate(value);
            default:
                return value;
        }
    };

    const columnData = (rowData: TData, rowIndex: number) => {
        return columns
            .filter(
                column =>
                    column.accessorKey !== pivotKey &&
                    column.visible !== false &&
                    rowData[column.accessorKey] !== undefined
            )
            .map(column => renderCell(column, rowIndex));
    };

    const renderCell = (column: CustomColumn<TData>, rowIndex: number) => {
        const cell = getCell(column, rowIndex);
        if (!cell) {
            return null;
        }

        const cellProps = column.meta?.getCellProps(cell.getContext());
        const hasWarningIcon = cellProps?.hasWarningIcon;
        const warningMessageMeta = cellProps?.warningMessage;

        return (
            <div
                key={`${String(column.accessorKey)}-${rowIndex}`}
                className="grid w-full grid-cols-2 gap-x-6 border border-blue-100 p-2"
            >
                <div data-testid="column-header">{column.header}</div>
                <div className="flex">
                    {hasWarningIcon && renderWarningIcon(cell.id, warningMessageMeta, column, cellProps)}
                    <div className={cellProps?.warningColor ? 'text-warning' : ''}>
                        {formatValue(column.cellType, String(cell.getValue()))}
                    </div>
                </div>
            </div>
        );
    };

    const getCell = (column: CustomColumn<TData>, rowIndex: number) => {
        return table
            ?.getRowModel()
            ?.rows[rowIndex]?.getVisibleCells()
            .find(c => c.column.id === column.accessorKey);
    };

    const renderWarningIcon = (
        cellId: string,
        warningMessageMeta: string | undefined,
        column: CustomColumn<TData>,
        cellProps: MetaCellProps
    ) => {
        return (
            <InfoTooltip
                placement={'top'}
                className={`pt-[2.5px] pr-2.5 [&_>svg]:h-6 [&_>svg]:w-6 ${
                    cellProps?.warningColor ? 'text-warning' : ''
                }`}
                id={cellId}
            >
                <p>{warningMessageMeta || column.warningMessage || ''}</p>
            </InfoTooltip>
        );
    };

    const renderRow = (row: Row<TData>, rowIndex: number) => {
        const rowData = row.original;
        const isFundRow = checkIfFundRow(rowData);
        const projectId = getProjectId(rowData);
        const isExpanded = checkIfExpanded(projectId);

        if (shouldSkipRender(isFundRow, isExpanded)) {
            return null;
        }

        const header = getHeader(isFundRow);

        return (
            <div key={rowIndex} className="w-full">
                {renderMainRow(rowData, projectId, header)}
                {renderExpandedContent(isExpanded, isFundRow, rowData, rowIndex)}
            </div>
        );
    };

    const checkIfFundRow = (rowData: any) => {
        return 'beginDate' in rowData;
    };

    const getProjectId = (rowData: any) => {
        return rowData[idKey] || rowData.partnerId;
    };

    const checkIfExpanded = (projectId: string) => {
        return projectId !== selectedRowId;
    };

    const shouldSkipRender = (isFundRow: boolean, isExpanded: boolean) => {
        return isFundRow && !isExpanded;
    };

    const getHeader = (isFundRow: boolean) => {
        const pivotColumn = columns.find(column => column.accessorKey === pivotKey);
        return isFundRow && overrideRowHeader ? 'Fund' : pivotColumn?.header ?? 'Year';
    };

    const renderMainRow = (rowData: any, projectId: string, header: string | undefined) => {
        return (
            <div className={getRowClasses(rowData)} onClick={() => handleRowClick(projectId)} role="row">
                <div data-testid="pivot-header" className="font-bold">
                    {header}
                </div>
                <div className="text-blue-accessible relative flex underline underline-offset-2">
                    {formatValue('text', rowData['beginDate'] || rowData[pivotKey])}
                    {!useDefaultRowHandler && renderRowIcon(selectedRowId, projectId, rowData)}
                </div>
            </div>
        );
    };

    const renderExpandedContent = (isExpanded: boolean, isFundRow: boolean, rowData: any, rowIndex: number) => {
        if (isExpanded || !isFundRow) {
            return columnData(rowData, rowIndex);
        }
        return null;
    };

    const renderRowIcon = (selectedRowId: string | null | undefined, projectId: any, rowData: TData) => {
        const hasSubData = rowData.subData?.length > 0;

        return (
            <div className="absolute top-0 right-0 mt-1">
                {hasSubData &&
                    (selectedRowId === projectId ? (
                        <ChevronDown className="h-4 w-4 text-blue-900" />
                    ) : (
                        <ChevronRight />
                    ))}
            </div>
        );
    };

    return (
        <div className="mt-8 mb-20" role="table" aria-label="Funds Summary Mobile View">
            {table.getRowModel().rows.map(renderRow)}
            {pagination && paginationCallback && (
                <TableFooter
                    pagination={pagination}
                    totalItems={totalItems}
                    paginationCallback={paginationCallback}
                    paginationDetail={paginationDetail}
                    firstItemIndex={firstItemIndex ?? 0}
                    lastItemIndex={lastItemIndex ?? 0}
                    pageCount={pageCount ?? 0}
                />
            )}
        </div>
    );
};

export { MobileTable };
