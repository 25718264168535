import { GuidelineData } from './domain';
type Props = {
    guidelineData: GuidelineData;
};

export const GuidelineMobileDetail = ({ guidelineData }: Props) => {
    return (
        <article className="flex flex-col border-t-2 border-blue-100 p-2">
            <div
                className="ck-content content-wrapper mt-5"
                dangerouslySetInnerHTML={{
                    __html: guidelineData.content,
                }}
            ></div>
        </article>
    );
};
