import { useTranslation } from 'react-i18next';

import { fundStatuses } from './constants';
import { CoInnovationFund } from './domain';
import FundStatusFlowItem from './FundStatusFlowItem';
import { getFundStatusTranslationKey } from './getFundStatusTranslationKey';

import { namespaces } from '@shared/constants';
import { formatDateWithHour } from '@shared/formatting';
import { ContactBox } from '@shared/ui';

type FundStatusFlowProps = {
    data: CoInnovationFund;
};

function FundStatusFlow({ data }: FundStatusFlowProps) {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);
    const isPending = data && data.statusId === fundStatuses.pending;
    const isChangeRequestPending = data && data.statusId === fundStatuses.changeRequestPending;

    type StatusInfoProps = {
        drawLine: boolean | undefined;
    };

    type PendingStateType = {
        isChangeRequestPending: boolean;
    };

    type TitleContactWrapper = {
        title: string;
        contact:
            | {
                  id: string;
                  name: string;
                  email: string;
                  photo?: string;
              }
            | undefined;
    };

    function getTitleBasedOnStatus(statusId: number): TitleContactWrapper {
        const result: TitleContactWrapper = {
            contact: undefined,
            title: '',
        };

        switch (statusId) {
            case fundStatuses.pending:
                result.title = t('coInnovationFunds.fundsManagement.fundsPendingValidation');
                result.contact = data.submittedByContact;
                break;
            case fundStatuses.approved:
            case fundStatuses.changeRequestPending:
                result.title = t('coInnovationFunds.fundsManagement.fundsApproved');
                result.contact = data.validatedByContact;
                break;
            case fundStatuses.rejected:
                result.title = t('coInnovationFunds.fundsManagement.fundsRejected');
                result.contact = data.validatedByContact;
                break;
            case fundStatuses.canceled:
                result.title = t('coInnovationFunds.fundsManagement.fundsCanceled');
                result.contact = data.submittedByContact;
                break;
            default:
                break;
        }

        return result;
    }

    function StatusInformationSection({ drawLine }: StatusInfoProps) {
        if (!data) {
            return null;
        }

        const pageData = getTitleBasedOnStatus(data.statusId);

        return (
            <div className="relative pl-4 pb-4">
                <div
                    className="absolute left-0 top-0 h-4 w-4 rounded-full bg-blue-800"
                    style={{ transform: 'translate(-50%, 3px)' }}
                ></div>
                <div className="flex grow flex-col">
                    <h3 className="mb-[6px] text-left text-base">{`${t(pageData.title)} - ${formatDateWithHour(
                        data.validationDate ?? ''
                    )}`}</h3>
                    {pageData.contact && (
                        <ContactBox
                            email={pageData.contact.email}
                            name={pageData.contact.name}
                            photo={pageData.contact.photo}
                        />
                    )}
                    {/* Line between circles if this is the last element in the flow (aka no change request) */}
                    {isPending ||
                        (drawLine && (
                            <div
                                className="absolute left-0 top-0 bottom-0 w-0.5 bg-blue-800"
                                style={{ transform: 'translateY(3px)' }}
                            ></div>
                        ))}
                </div>
            </div>
        );
    }

    function SponsorsSection({ isChangeRequestPending }: PendingStateType) {
        return data?.sponsors ? (
            <div className="relative pl-4 pb-4">
                <div
                    className="absolute left-0 top-0 h-4 w-4 rounded-full bg-white ring-2 ring-inset ring-blue-800"
                    style={{ transform: 'translate(-50%, 3px)' }}
                ></div>
                <h3 className="mb-[6px] text-left text-base">
                    {isChangeRequestPending
                        ? `${t('coInnovationFunds.fundsManagement.statusChangeRequestPendingValidation')}`
                        : `${t('coInnovationFunds.fundsManagement.fundsPendingValidation')}`}
                </h3>
                {data.sponsors.map((sponsor, index) => (
                    <div key={index} className="flex grow flex-col">
                        <ContactBox email={sponsor.email} name={sponsor.name} photo={sponsor.photo} />
                    </div>
                ))}
            </div>
        ) : null;
    }

    return (
        <>
            {/* Heading status text */}
            <div className="flex gap-2 pl-10" role="status" aria-label="Fund Status">
                <div>
                    <h3 id="status-title" className="mb-[6px] text-base">{`${t(
                        'coInnovationFunds.fundsManagement.status'
                    )}:`}</h3>
                </div>
                <div>
                    <div className="text-sm font-light" aria-labelledby="status-title">
                        {t(getFundStatusTranslationKey(data.statusId))}
                    </div>
                </div>
            </div>

            <div className="space-between relative flex grow flex-col overflow-auto px-10">
                {/* Submitter (always first element in the status flow) */}
                <FundStatusFlowItem
                    title={'coInnovationFunds.fundsManagement.fundsSubmited'}
                    key={data.beginDate}
                    date={data.submissionDate}
                    contactEmail={data.submittedByContact.name}
                    contactName={data.submittedByContact.name}
                    contactPhoto={data.submittedByContact.photo}
                    isPending={isPending}
                    isChangeRequestPending={isChangeRequestPending}
                    lastItem={false}
                />
                {/* Sponsor approving / declining / pending (always second element in the status flow) */}
                {!isPending && <StatusInformationSection drawLine={data.changeRequests?.some(x => x)} />}
                {isPending && <SponsorsSection isChangeRequestPending={false} />}
                {/* Change requests / approvals / declines */}
                {data.changeRequests?.some(x => x) &&
                    data.changeRequests.map(function (x, i) {
                        return (
                            <div key={x.submissionDate}>
                                <FundStatusFlowItem
                                    title={
                                        x.validationDate
                                            ? 'coInnovationFunds.fundsManagement.statusChangeRequestCreated'
                                            : 'coInnovationFunds.fundsManagement.statusChangeRequestPending'
                                    }
                                    isPending={isPending}
                                    isChangeRequestPending={isChangeRequestPending}
                                    date={x.submissionDate}
                                    contactEmail={x.submittedByContact.email}
                                    contactName={x.submittedByContact.name}
                                    contactPhoto={x.submittedByContact.photo}
                                    lastItem={!data?.changeRequests?.[i + 1] && !x.validationDate}
                                />
                                {x.validationDate && x.validatedByContact && (
                                    <FundStatusFlowItem
                                        title={
                                            x.changeRequestStatusId === 1
                                                ? 'coInnovationFunds.fundsManagement.statusChangeRequestApproved'
                                                : x.changeRequestStatusId === 3
                                                ? 'coInnovationFunds.fundsManagement.statusChangeRequestCanceled'
                                                : 'coInnovationFunds.fundsManagement.statusChangeRequestDeclined'
                                        }
                                        isPending={isPending}
                                        isChangeRequestPending={isChangeRequestPending}
                                        date={x.validationDate}
                                        contactEmail={x.validatedByContact.email}
                                        contactName={x.validatedByContact.name}
                                        contactPhoto={x.validatedByContact.photo}
                                        lastItem={!data?.changeRequests?.[i + 1]}
                                    />
                                )}
                            </div>
                        );
                    })}
                {isChangeRequestPending && <SponsorsSection isChangeRequestPending />}
            </div>
        </>
    );
}

export default FundStatusFlow;
