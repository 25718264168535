import { useEffect, useState } from 'react';

import '../../../shared/ck-editor.scss';
import { GuidelineData } from './domain';
import { GuidelineDetails } from './GuidelineDetails';
import { GuidelinesMobileTable } from './GuidelinesMobileTable';
import { GuidelinesTable } from './GuidelinesTable';
import { useGetGuidelinesQuery } from './useGetGuidelinesQuery';

import { useResizeMediaQuery } from '@features/layout';

export const Guidelines = () => {
    const { data: guidelineListData, isLoading, isError } = useGetGuidelinesQuery();

    const [selectedGuideline, setSelectedGuideline] = useState<GuidelineData | null>(null);
    const { isMobile } = useResizeMediaQuery();

    useEffect(() => {
        if (
            guidelineListData &&
            Array.isArray(guidelineListData) &&
            guidelineListData.length > 0 &&
            !selectedGuideline
        ) {
            setSelectedGuideline(guidelineListData[0] || null);
        }
    }, [guidelineListData, selectedGuideline]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading guideline data</div>;
    }

    return (
        <div className="flex flex-grow flex-row px-6 md:px-8" id="priorities">
            {!isMobile ? (
                <div className="m-8 flex flex-row gap-12">
                    <div>
                        {guidelineListData?.length > 0 ? (
                            <GuidelinesTable
                                guidelineListData={guidelineListData}
                                selectedGuideline={selectedGuideline}
                                setSelectedGuideline={setSelectedGuideline}
                            />
                        ) : null}
                    </div>
                    <div>{selectedGuideline ? <GuidelineDetails selectedGuideline={selectedGuideline} /> : null}</div>
                </div>
            ) : (
                <GuidelinesMobileTable
                    guidelineListData={guidelineListData || []}
                    selectedGuideline={selectedGuideline}
                    setSelectedGuideline={setSelectedGuideline}
                />
            )}
        </div>
    );
};
