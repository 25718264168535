import { useTranslation } from 'react-i18next';

import { DiscoveryCard } from './DiscoveryCard';
import { useDiscoveryCardsQuery } from './useDiscoveryCardsQuery';

import { ReactComponent as NoData } from '@assets/images/noData.svg';

const DiscoveryCardSection = () => {
    const { t } = useTranslation();
    const { data } = useDiscoveryCardsQuery();

    return (
        <div>
            {data?.items && data.items.length > 0 ? (
                <div className="flex gap-6">
                    {data.items.map(card => (
                        <DiscoveryCard key={card.id} card={card} />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col items-center justify-center">
                    <NoData />
                    <p className="mt-6 font-bold">{t('errors.noResultsFound')}</p>
                </div>
            )}
        </div>
    );
};

export { DiscoveryCardSection };
