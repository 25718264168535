import { PropsWithChildren } from 'react';

import classNames from 'classnames';
import { PlacesType, Tooltip as ReactTooltip } from 'react-tooltip';

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg';

export type TooltipProps = {
    id: string;
    title?: string;
    noArrow?: boolean;
    placement?: PlacesType;
    className?: string;
};

const InfoTooltip = ({ id, placement, title, noArrow, children, className }: PropsWithChildren<TooltipProps>) => {
    return (
        <>
            <i
                tabIndex={0}
                className={classNames(
                    'inline-block cursor-pointer align-middle text-blue-900 [&_svg]:h-4 [&_svg]:w-4',
                    className
                )}
                data-tooltip-id={id}
                data-testid="tooltip-icon"
            >
                <InfoIcon data-html2canvas-ignore />
            </i>
            <ReactTooltip
                id={id}
                place={placement}
                noArrow={noArrow}
                className={'tooltip'}
                style={{
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    textAlign: 'left',
                    overflow: 'visible',
                }}
            >
                {title ? <div className="mb-1 text-base">{title}</div> : <p></p>}
                {children}
            </ReactTooltip>
        </>
    );
};

export { InfoTooltip };
