import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getReportDetail } from './api';
import { ReportDetail, reportDetailValidator } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'report';

export const useGetReportDetailQuery = (genAiId: string) => {
    const finalParams = useMemo(() => genAiId || {}, [genAiId]);
    const cacheKey = useMemo(() => `${queryKey}-${JSON.stringify(finalParams)}`, [finalParams]);

    return useQuery([queryKey, finalParams], async () => getReportDetail(genAiId), {
        onSuccess: (data: ReportDetail) => {
            saveToCache(cacheKey, data || {});
        },
        placeholderData: () => {
            const cachedData = retrieveFromCache(cacheKey, reportDetailValidator.parse);
            return cachedData ? { report: cachedData.report } : { report: {} };
        },
    });
};
