import { PaginationState } from '@tanstack/react-table';

import {
    SortParams,
    validatorListParser,
    coInnovationFundParser,
    CoInnovationFundListResponse,
    coInnovationFundListResponseParser,
    ValidatorsListResponse,
    CoInnovationFund,
    changeRequestParser,
    ChangeRequest,
    CoInnovationChangeRequestValidationParam,
    CoInnovationFundValidationParam,
    CoInnovationFundsSummaryAddFunds,
    CoInnovationFundChangeRequestParam,
    GetFundsRequest,
} from './domain';

import { agent, queryStringComposer } from '@shared/api';

const apiRoutes = {
    getFunds: '/api/coInnovationFunds/management/list',
    getFundById: '/api/coInnovationFunds/management/byid',
    getValidators: '/api/sponsors/list',
    cancelFund: '/api/coInnovationFunds/management/cancel',
    changeRequest: '/api/coInnovationFunds/change-request',
    getActiveChangeRequestByFundId: '/api/coInnovationFunds/change-request/byFund',
    addFunds: '/api/coinnovationfunds',
    validateFund: '/api/coinnovationfunds/management/validate',
    validateChangeRequest: '/api/coinnovationfunds/change-request/validate',
    cancelChangeRequest: '/api/coinnovationfunds/change-request/cancel',
};

export const getFunds = async (
    { pageIndex, pageSize }: PaginationState,
    { sorting }: SortParams,
    { partnerIds, statusIds, validatorIds, beginDateFrom, beginDateTo }: GetFundsRequest
) => {
    const queryParams = queryStringComposer()
        .addQueryStringValueFor('partnerIds', partnerIds)
        .addQueryStringValueFor('statusIds', statusIds)
        .addQueryStringValueFor('validatorIds', validatorIds)
        .addQueryStringValueFor('beginDateFrom', beginDateFrom ? [beginDateFrom] : undefined)
        .addQueryStringValueFor('beginDateTo', beginDateTo ? [beginDateTo] : undefined)
        .addQueryStringValueFor('Page', [pageIndex + 1])
        .addQueryStringValueFor('PageSize', [pageSize])
        .addQueryStringSortingFor(sorting)
        .compose();

    const response = await agent.get<CoInnovationFundListResponse>(`${apiRoutes.getFunds}${queryParams}`);
    return response ? coInnovationFundListResponseParser.parseAsync(response) : null;
};

export const getFundById = async (id: string) => {
    const response = await agent.get<CoInnovationFund>(`${apiRoutes.getFundById}/${id}`);
    return coInnovationFundParser.parseAsync(response);
};

export const getValidators = async (): Promise<ValidatorsListResponse | null> => {
    const response = await agent.get<ValidatorsListResponse>(apiRoutes.getValidators);
    return response ? validatorListParser.parseAsync(response) : null;
};

export const cancelFund = async (id: string) => {
    await agent.patch<Record<string, never>, void>(`${apiRoutes.cancelFund}/${id}`, {});
};

export const postChangeRequest = async (param: CoInnovationFundChangeRequestParam) => {
    await agent.post<CoInnovationFundChangeRequestParam, void>(`${apiRoutes.changeRequest}`, param);
};

export const getActiveCrByFundId = async (fundId: string) => {
    const response = await agent.get<ChangeRequest>(`${apiRoutes.getActiveChangeRequestByFundId}/${fundId}`);
    return changeRequestParser.parseAsync(response);
};

export const addFunds = async (fund: CoInnovationFundsSummaryAddFunds) =>
    agent.post<CoInnovationFundsSummaryAddFunds, void>(apiRoutes.addFunds, fund);

export const validateFund = async (param: CoInnovationFundValidationParam) => {
    await agent.patch<CoInnovationFundValidationParam, void>(`${apiRoutes.validateFund}/${param.id}`, param);
};

export const validateChangeRequest = async (param: CoInnovationChangeRequestValidationParam) => {
    await agent.patch<CoInnovationChangeRequestValidationParam, void>(
        `${apiRoutes.validateChangeRequest}/${param.id}`,
        param
    );
};

export const cancelCR = async (id: string) => {
    await agent.patch<Record<string, never>, void>(`${apiRoutes.cancelChangeRequest}/${id}`, {});
};
