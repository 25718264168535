import { useEffect, useState } from 'react';

import { getTopContentPage } from './api';
import { DiscoveryCardSection } from './DiscoveryCardSection';
import { TopContentItem } from './domain';
import TopContent from './TopContent';

import { Spinner } from '@shared/ui';

const Discovery = () => {
    const [topContentData, setTopContentData] = useState<TopContentItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getTopContentPage();
                setTopContentData(data);
            } catch (error) {
                console.error('Error fetching top content data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <section className=" pt-header-height" id="genai">
                {loading ? (
                    <div className="flex h-[700px] w-full flex-grow items-center justify-center">
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {/* padding top is only until we implement the top navigation menu */}
                        <div className="bg-neutral-oak-5 px-[200px] pt-40 pb-6">
                            <DiscoveryCardSection></DiscoveryCardSection>
                        </div>
                        <div className="mx-16">
                            <TopContent
                                topContentData={topContentData.map(item => ({
                                    ...item,
                                }))}
                            />
                        </div>
                    </>
                )}
            </section>
        </>
    );
};

export { Discovery };
