import { useState, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

type PaginationState = {
    pageIndex: number;
    pageSize: number;
};

type UsePaginationTotalsProps = {
    totalItems: number;
    pagination: PaginationState;
};

type UsePaginationProps = {
    pageSize?: number;
};

const DEFAULT_PAGE_SIZE = 10;

export const usePagination = ({ pageSize = DEFAULT_PAGE_SIZE }: UsePaginationProps = {}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const getPageFromUrl = () => {
        const urlParams = new URLSearchParams(location.search);
        const page = urlParams.get('page');
        return page ? parseInt(page, 10) - 1 : 0;
    };

    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: getPageFromUrl() || 0,
        pageSize: pageSize || DEFAULT_PAGE_SIZE,
    });

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        urlParams.set('page', (pagination.pageIndex + 1).toString());
        navigate(`${location.pathname}?${urlParams.toString()}`, { replace: false });
    }, [pagination.pageIndex]);

    return {
        pagination,
        setPagination,
        firstItemIndex: pagination.pageIndex * pagination.pageSize + 1,
    };
};

export const usePaginationTotals = ({ totalItems, pagination }: UsePaginationTotalsProps) => {
    const pageCount = Math.ceil(totalItems / pagination.pageSize);
    return {
        pageCount,
        lastItemIndex: Math.min((pagination.pageIndex + 1) * pagination.pageSize, totalItems),
    };
};
