import { Link } from 'react-router-dom';

import { DiscoveryCardData } from './domain';

import { ReactComponent as ArrowRight } from '@assets/icons/arrowRight.svg';

type Props = {
    card: DiscoveryCardData;
};

const DiscoveryCard = ({ card }: Props) => {
    return (
        <div className=" min-h-60 flex w-full flex-1 items-start rounded-lg bg-white shadow-xl">
            <img src={card.image.url} className=" h-full w-44" />
            <div className="flex flex-1 flex-col items-start justify-between self-stretch p-6 pl-3">
                <h3 className="mb-[6px] h-12 text-base font-bold text-neutral-oak-dark">{card.name}</h3>
                <p className="mb-2 text-base text-sm font-normal leading-[22px] text-secondary-oak">
                    {card.description}
                </p>
                <div className="flex h-9 items-center justify-end self-stretch">
                    <Link to={card.url}>
                        <ArrowRight className="h-9 w-9 rounded bg-primary-blue-100 stroke-white p-2" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export { DiscoveryCard };
