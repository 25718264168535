import { GuidelineData } from './domain';

type Props = {
    selectedGuideline: GuidelineData;
};

export const GuidelineDetails = ({ selectedGuideline }: Props) => {
    return (
        <article className="flex max-w-4xl flex-col">
            <div
                className="ck-content content-wrapper mt-5"
                dangerouslySetInnerHTML={{
                    __html: selectedGuideline.content,
                }}
            ></div>
        </article>
    );
};
