import { ReactNode } from 'react';

import { InfoTooltip, TooltipProps } from '../tooltip';

type Props = {
    label: string;
    color?: string;
    percentage?: number;
    value?: string | number;
    tooltip?: Omit<TooltipProps, 'title' | 'noArrow'> & { content?: ReactNode };
    textColor?: string;
};

function BarChartLabel({ label, value, percentage, color, tooltip, textColor }: Props) {
    return (
        <div className="my-3 text-black-800">
            {value || percentage ? (
                <div className="mb-1 text-base">
                    {value}
                    {percentage ? ` (${percentage}%)` : null}
                </div>
            ) : null}

            <div className="flex items-center text-sm">
                {color ? (
                    <span className="mr-1 inline-block h-[9px] w-[9px] rounded" style={{ backgroundColor: color }} />
                ) : null}

                <span className="mr-1 inline-block" style={{ color: textColor ? textColor : 'black' }}>
                    {label}
                </span>

                {tooltip ? (
                    <InfoTooltip {...tooltip} title={label}>
                        {tooltip.content}
                    </InfoTooltip>
                ) : null}
            </div>
        </div>
    );
}

export { BarChartLabel };
