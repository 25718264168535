import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getFunds } from './api';
import { convertFormStateToApiParam } from './convertFormStateToApiParam';
import { SortParams, coInnovationFundListResponseParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';
import { useStore } from '@store';

const queryKey = 'fundsList';

type Props = {
    pageIndex: number;
    pageSize: number;
    paginationReset?: () => void;
    sort: SortParams;
};

export const useGetFundsQuery = ({ pageIndex, pageSize, sort, paginationReset }: Props) => {
    const [initialRun, setInitialRun] = useState(true);
    const { filter } = useStore(state => state.coInnovationFunds);

    useEffect(() => {
        if (!initialRun) {
            if (paginationReset && pageIndex !== 0) {
                paginationReset();
            }
        }
    }, [filter]);

    useEffect(() => {
        setInitialRun(false);
    }, [pageIndex, pageSize]);
    return useQuery(
        [queryKey, sort, filter, pageIndex, pageSize],
        async () => getFunds({ pageIndex, pageSize }, sort, convertFormStateToApiParam(filter)),
        {
            suspense: false,
            keepPreviousData: true,
            onSuccess: data => saveToCache(queryKey, data),
            placeholderData: () => retrieveFromCache(queryKey, coInnovationFundListResponseParser.parse),
        }
    );
};
