import { FC } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as ChevronRight } from '@assets/icons/chevronRight.svg';

type BreadCrumsProps = {
    breadcrums: { name: string; link?: string }[];
    isContainer?: boolean;
};

const BreadCrums: FC<BreadCrumsProps> = ({ breadcrums, isContainer = false }) => {
    return (
        <div className="sm:bg-scroll-background-color flex flex-col  pt-1">
            <div className={`${isContainer && 'sm:container'} bg-scroll-background-color flex px-6 py-2`}>
                {breadcrums.map((item, index) => {
                    const isLast = index === breadcrums.length - 1;
                    return (
                        <div key={index} className="flex items-center gap-2 px-1">
                            {!isLast ? (
                                <Link
                                    to={item?.link || '../'}
                                    className="text-primary focus:border-breadcrum-focus focus:text-breadcrum-focus text-sm text-breadcrumb-default hover:text-breadcrumb-hover focus:border-2 focus:outline-none active:text-breadcrumb-pressed"
                                >
                                    {item.name}
                                </Link>
                            ) : (
                                <span className="text-primary text-sm text-breadcrumb-active">{item.name}</span>
                            )}
                            <div className="flex h-6 w-6 items-center justify-center px-1">
                                {!isLast && <ChevronRight className="stroke-grey-500" />}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export { BreadCrums };
