import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { getProjectList } from './api';
import { DetailProjects, coInnovationFundsDetailProjectsParser } from './domain';

import { anyFilter } from '@features/coInnovationFunds/shared';
import { retrieveFromCache, saveToCache } from '@shared/caching';
import { PagerProps } from '@shared/ui';
import { SortingProps } from '@shared/ui/sorting';
import { useStore } from '@store';

type Props = {
    paginationReset?: () => void;
};

const queryKey = 'projectsDetails';

export const useProjectsDetailsQuery = ({
    skip,
    take,
    id: orderBy,
    desc,
    paginationReset,
}: PagerProps & SortingProps & Props) => {
    const [initialRun, setInitialRun] = useState(true);
    const filter = useStore(state => state.coInnovationFunds.filter);

    useEffect(() => {
        if (!initialRun && paginationReset && skip !== 0) {
            paginationReset();
        }
    }, [filter]);

    useEffect(() => {
        setInitialRun(false);
    }, [skip, take]);

    const page = skip / take;

    const isCacheEnabled = page === 0 && !anyFilter(filter) && !orderBy && !desc;

    const query = useQuery<DetailProjects | null>(
        [queryKey, { page, filter: filter, orderBy, desc }],
        async () => {
            const response = await getProjectList(skip, take, filter, orderBy, desc);
            if (isCacheEnabled) {
                saveToCache(queryKey, response);
            }
            return response;
        },
        {
            keepPreviousData: true,
            suspense: false,
            placeholderData: () => {
                if (isCacheEnabled) {
                    return retrieveFromCache(queryKey, coInnovationFundsDetailProjectsParser.parse);
                }
                return undefined;
            },
        }
    );

    return { query, filter };
};
