import { PaginationState } from '@tanstack/react-table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Pager, PageJumper } from '../pagination';

type PaginationProps = {
    pagination: PaginationState;
    totalItems: number;
    paginationCallback: (pagination: PaginationState) => void;
    firstItemIndex: number;
    lastItemIndex: number;
    pageCount: number;
    paginationDetail?: boolean;
};

const Pagination = ({
    pagination,
    totalItems,
    paginationCallback,
    firstItemIndex,
    lastItemIndex,
    pageCount,
    paginationDetail,
}: PaginationProps) => {
    const { t: commonT } = useTranslation();

    const onPageChangeCallback = (page: number) => {
        const newPagination = { pageIndex: page - 1, pageSize: pagination.pageSize };
        paginationCallback(newPagination);
    };

    return (
        <div className="flex items-center text-xs">
            {!paginationDetail && (
                <span className="mr-3 flex whitespace-nowrap text-blue-800" data-testid="paginationSummary-entries">
                    {totalItems === 0
                        ? commonT('paging.noEntries')
                        : commonT('paging.pagingEntries', {
                              first: firstItemIndex,
                              last: lastItemIndex,
                              total: totalItems.toString(),
                          })}
                </span>
            )}

            <div className="mt-4 flex-1 justify-center text-center">
                <Pager pageCount={pageCount} currentPage={pagination.pageIndex + 1} onChange={onPageChangeCallback} />
            </div>
            {!paginationDetail && (
                <div className="ml-3 flex items-center justify-end">
                    <div
                        className={classNames('flex items-center whitespace-nowrap text-xs text-blue-800', {
                            'mr-4 self-stretch border-r border-blue-200/60 pr-4': pageCount > 1,
                        })}
                    >
                        {commonT('paging.totalPages', { pageCount })}
                    </div>

                    <PageJumper id="projectsDetails" onChange={onPageChangeCallback} pageCount={pageCount} />
                </div>
            )}
        </div>
    );
};

export { Pagination };
