import { Suspense, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Buttons, CoInnovationFilter, FilterContent, PageType } from '../shared';

import { ByPartnerTab } from './funds/tabs/ByPartner/ByPartnerTab';
import { SummaryFilters } from './SummaryFilters';

import { ReactComponent as DollarIcon } from '@assets/icons/dollarIcon.svg';
import { namespaces } from '@shared/constants';
import { DialogBody, DialogContainer, DialogFooter, DialogHeader, Spinner, Tabs } from '@shared/ui';
import { useStore } from '@store';

const Summary = () => {
    const { setShowFilterDialog, showFilterDialog, setFilter, setPage } = useStore(state => state.coInnovationFunds);
    const applyFilterCallback = (data: CoInnovationFilter) => {
        setFilter(data);
        setShowFilterDialog(false);
    };
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    useEffect(() => {
        setPage(PageType.Summary);
    }, []);

    return (
        <div id="summary" className="px-6 md:px-8">
            <Tabs
                elements={[
                    {
                        testid: 'summaryByPartnerTab',
                        name: t('coInnovationFunds.summary.tabName'),
                        icon: <DollarIcon />,
                        content: <ByPartnerTab />,
                        withFallbackInContent: false,
                    },
                ]}
            />

            {showFilterDialog ? (
                <DialogContainer setShowDialog={setShowFilterDialog} showDialog={showFilterDialog}>
                    <DialogHeader onCloseButtonClick={() => setShowFilterDialog(false)}>
                        {t('coInnovationFunds.filters.title')}
                    </DialogHeader>

                    <DialogBody>
                        <Suspense fallback={<Spinner />}>
                            <FilterContent applyFilterCallback={applyFilterCallback} formName="summaryFilter">
                                <SummaryFilters />
                            </FilterContent>
                        </Suspense>
                    </DialogBody>

                    <DialogFooter>
                        <Buttons formName="summaryFilter" />
                    </DialogFooter>
                </DialogContainer>
            ) : null}
        </div>
    );
};

export { Summary };
