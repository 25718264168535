import { Dispatch, SetStateAction, useCallback } from 'react';

import { ChangeRequestValidationDialog } from '../summary/funds/ChangeRequestValidationDialog';
import { FundValidationDialog } from '../summary/funds/FundValidationDialog';

import { CoInnovationFund } from './domain';
import { FundCancelDialog } from './FundCancelDialog';
import { FundChangeRequestCancelDialog } from './FundChangeRequestCancelDialog';
import { FundChangeRequestDialog } from './FundChangeRequestDialog';
import { SelectedFundState } from './Funds';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';

const canCancelFundPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
const canValidateFundPermissions = [permissions.coInnovationFunds.fundsManagement.validation];
const canChangeRequestPermissions = [
    permissions.coInnovationFunds.allRequests.create,
    permissions.coInnovationFunds.partnerRequests.create,
];
const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

type FundDialogsProps = {
    selectedFundState: SelectedFundState | undefined;
    setSelectedFundState: Dispatch<SetStateAction<SelectedFundState | undefined>>;
    data: CoInnovationFund | undefined;
};

export const FundDetailDialog = ({ setSelectedFundState, selectedFundState, data }: FundDialogsProps) => {
    const onFundValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: data?.id || '',
                types: previousState?.types?.filter(type => type !== 'approve' && type !== 'reject') || [],
            })),
        [setSelectedFundState, data?.id]
    );

    const onFundCRValidationClose = useCallback(
        () =>
            setSelectedFundState(previousState => ({
                id: data?.id || '',
                types: previousState?.types?.filter(type => type !== 'approveCR' && type !== 'rejectCR') || [],
            })),
        [setSelectedFundState, data?.id]
    );
    if (!selectedFundState) {
        return null;
    }

    return (
        <>
            <HasPermission neededPermissions={canCancelFundPermissions}>
                <FundCancelDialog
                    setSelectedFundState={setSelectedFundState} // Ensure setSelectedFundState is passed down correctly
                    selectedFund={selectedFundState?.types.includes('cancel') ? data : undefined}
                />
            </HasPermission>
            <HasPermission neededPermissions={canValidateFundPermissions}>
                <FundValidationDialog
                    isOpen={!!selectedFundState?.types.find(type => ['approve', 'reject'].includes(type))}
                    id={selectedFundState?.id || ''}
                    modalType={selectedFundState?.types.includes('approve') ? 'approve' : 'reject'}
                    onClose={onFundValidationClose}
                />
            </HasPermission>
            {selectedFundState?.types.includes('changeRequest') && (
                <HasPermission neededPermissions={canChangeRequestPermissions}>
                    <FundChangeRequestDialog
                        setSelectedFundState={setSelectedFundState}
                        selectedFund={selectedFundState?.types.includes('changeRequest') ? data : undefined}
                    />
                </HasPermission>
            )}
            {selectedFundState?.types.includes('cancelCR') && (
                <HasPermission neededPermissions={canCancelFundPermissions}>
                    <FundChangeRequestCancelDialog
                        setSelectedFundState={setSelectedFundState}
                        selectedFund={selectedFundState?.types.includes('cancelCR') ? data : undefined}
                    />
                </HasPermission>
            )}
            {selectedFundState?.types.includes('approveCR') && (
                <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                    <ChangeRequestValidationDialog
                        isOpen={!!selectedFundState?.types.find(type => ['approveCR'].includes(type))}
                        id={selectedFundState.id}
                        modalType={'approve'}
                        onClose={onFundCRValidationClose}
                        selectedFund={selectedFundState?.types.includes('approveCR') ? data : undefined}
                    />
                </HasPermission>
            )}
            {selectedFundState?.types.includes('rejectCR') && (
                <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                    <ChangeRequestValidationDialog
                        isOpen={!!selectedFundState?.types.find(type => ['rejectCR'].includes(type))}
                        id={selectedFundState.id}
                        modalType={'reject'}
                        onClose={onFundCRValidationClose}
                        selectedFund={selectedFundState?.types.includes('rejectCR') ? data : undefined}
                    />
                </HasPermission>
            )}
        </>
    );
};
