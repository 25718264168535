import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ContentType, StatusType } from './domain';

import { ReactComponent as ArrowRight } from '@assets/icons/arrowRight.svg';
import { ReactComponent as Clock } from '@assets/icons/clock.svg';
import { ReactComponent as Innovation } from '@assets/icons/innovation.svg';
import { ReactComponent as Paper } from '@assets/icons/paper.svg';
import { ReactComponent as Earth } from '@assets/icons/quantum.svg';
import { ReactComponent as Tick } from '@assets/icons/tick-circle-small.svg';
import { ReactComponent as Topic } from '@assets/icons/topic.svg';
import { ReactComponent as Person } from '@assets/icons/user.svg';
import { InitiativeStatus } from '@features/initiatives/domain';
import { namespaces } from '@shared/constants';
import { truncateString } from '@shared/formatting/formatString';

interface TopContentCardProps {
    title: string;
    authors?: string[];
    topics?: string[];
    contacts?: string[];
    step: string;
    status: string;
    type: ContentType;
    url: string;
}

const TopContentCard: React.FC<TopContentCardProps> = ({
    title,
    authors,
    contacts,
    step,
    status,
    type,
    url,
    topics,
}) => {
    const { t } = useTranslation(namespaces.features.discovery);
    const navigate = useNavigate();

    const handleRedirect = (url: string) => {
        navigate(`${url}`);
    };

    const renderIcon = () => {
        const iconProps = 'h-16 w-16 rounded-lg bg-neutral-oak-5 p-3';
        switch (type) {
            case ContentType.Article:
                return <Paper className={iconProps} />;
            case ContentType.Initiative:
                return <Innovation className={iconProps} />;
            case ContentType.Event:
                return <Earth className={iconProps} />;
            default:
                return null;
        }
    };

    const renderDetails = () => {
        switch (type) {
            case ContentType.Article:
                return (
                    <div className="text-xs text-oak-neutral">
                        <div className="flex">
                            <Person className="mr-1 items-center" />

                            <span className="flex items-center gap-1">
                                <span>{t('topContent.author')}: </span>
                                {authors && authors.length > 0 && (
                                    <>
                                        <span className="font-bold text-primary-blue-100">{authors[0]}</span>
                                        <span className="font-bold text-neutral-800">
                                            {authors.length > 1 ? ` + ${authors.length - 1}` : ''}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="flex">
                            <Topic className="mr-1 items-center" />

                            <span className="flex items-center gap-1">
                                <span>{t('topContent.topic')}: </span>
                                {topics && topics.length > 0 && (
                                    <>
                                        <span className="font-bold text-primary-blue-100">{topics[0]}</span>
                                        <span className="font-bold text-neutral-800">
                                            {topics.length > 1 ? ` + ${topics.length - 1}` : ''}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                );
            case ContentType.Initiative:
                return (
                    <div className="text-xs text-oak-neutral">
                        <div className="flex">
                            <Person className="mr-1 items-center" />

                            <span className="flex items-center gap-1">
                                <span>{t('topContent.contact')}: </span>
                                {contacts && contacts.length > 0 && (
                                    <>
                                        <span className="font-bold text-primary-blue-100">{contacts[0]}</span>
                                        <span className="font-bold text-neutral-800">
                                            {contacts.length > 1 ? ` + ${contacts.length - 1}` : ''}
                                        </span>
                                    </>
                                )}
                            </span>
                        </div>
                        <div className="flex">
                            <Tick className="mr-1" />
                            <span className="flex items-center gap-1">
                                <span>{t('topContent.step')}: </span>
                                <span className="font-bold text-primary-blue-100">
                                    {InitiativeStatus[Number(step)]}
                                </span>
                            </span>
                            <div className="ml-3 flex items-center gap-1">
                                <span>{t('topContent.status')}: </span>
                                <span className="font-bold text-primary-blue-100">{StatusType[status]}</span>
                            </div>
                        </div>
                    </div>
                );
            case ContentType.Event:
                return (
                    <div className="text-xs text-oak-neutral">
                        <div className="flex">
                            <Person className="mr-1 items-center" />
                            <span className="flex items-center gap-1">
                                <span>{t('topContent.author')}: </span>
                                <span className="font-bold text-primary-blue-100">{authors}</span>
                            </span>
                        </div>
                        <div className="flex">
                            <Clock className="mr-1 items-center" />
                            <span className="flex items-center gap-1">
                                <span>{t('topContent.contact')}: </span>
                                <span className="font-bold text-primary-blue-100">{contacts}</span>
                            </span>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <article
            className="group flex h-36 w-full cursor-pointer gap-3 rounded-xl border border-gray-100 p-3 transition hover:border-blue-500 hover:shadow-lg"
            onClick={() => handleRedirect(url)}
        >
            <div className="shrink-0 items-center  justify-center">{renderIcon()}</div>
            <div className="grow">
                <header>
                    <h4 className="mb-1 text-[12px] font-bold text-primary-blue-100">{type}</h4>
                </header>
                <div className="h-12">
                    <p className=" mb-1 text-base font-bold">{truncateString(title, 60)}</p>
                </div>
                {renderDetails()}
            </div>
            <div className="mt-auto opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                <ArrowRight className="mr-2 w-4 stroke-current text-gray-500 group-hover:text-blue-500" />
            </div>
        </article>
    );
};

export { TopContentCard };
