import { useQuery } from '@tanstack/react-query';

import { getGuidelines } from './api';
import { guidelineListParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const guidelinesDataQueryKey = 'guideLinesData';
export const useGetGuidelinesQuery = () =>
    useQuery(
        [guidelinesDataQueryKey],
        async () => {
            const response = await getGuidelines();
            const parsedResponse = guidelineListParser.parse(response);
            saveToCache(guidelinesDataQueryKey, parsedResponse);
            return parsedResponse;
        },
        {
            placeholderData: () => retrieveFromCache(guidelinesDataQueryKey, data => guidelineListParser.parse(data)),
        }
    );
