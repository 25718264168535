import { z } from 'zod';

const filterType: z.ZodType = z.lazy(() =>
    z.object({
        key: z.string(),
        value: z.string(),
        assingedToInitiative: z.number(),
        children: z.array(filterType).optional(),
    })
);

export const reportFilterParser = z.object({
    emergingTechnologies: z.array(filterType),
    steps: z.array(filterType),
    statuses: z.array(filterType),
    partners: z.array(filterType),
    itStreams: z.array(filterType),
    contacts: z.array(filterType),
});

export interface GetInitiativesFilterParam {
    emergingTechnologies: KeyValuePair[];
    steps: KeyValuePair[];
    statuses: KeyValuePair[];
    itStreams: KeyValuePair[];
    partners: KeyValuePair[];
    contacts: KeyValuePair[];
    title?: string;
}

export type FilterType = {
    key: string;
    value: string;
    assingedToInitiative: number;
    children?: FilterType[];
};

export type KeyValuePair = {
    key: string;
    value: string;
    assingedToInitiative: number;
};

export type Tag = {
    key: string;
    value?: string;
};

export enum ReportFilterType {
    EmergingTech = 'emergingTechnologies',
    Step = 'steps',
    Status = 'statuses',
    ItStreams = 'itStreams',
    Partners = 'partners',
    Contacts = 'contacts',
}

export enum InitiativeStatus {
    ConceptValidation = 0,
    Pilot = 1,
    Industrialization = 2,
    ProductionAtScale = 3,
}

const tagsParser = z.object({
    key: z.string(),
    value: z.string().optional(),
});

const contactParser = z.object({
    firstName: z.string(),
    lastName: z.string().optional(),
});

const itSteamParser = z
    .object({
        key: z.string(),
        value: z.string().optional(),
    })
    .optional();

const partnerParser = z.object({
    key: z.string(),
    value: z.string().optional(),
});

export const reportParser = z.object({
    name: z.string(),
    shortDescription: z.string(),
    contacts: z.array(contactParser),
    tags: z.array(tagsParser),
    initiativeStatus: z.number(),
    itStream: itSteamParser,
    partners: z.array(partnerParser),
    id: z.string(),
});

export const reportsValidator = z.object({
    reports: z.array(reportParser),
    filters: reportFilterParser,
});

export type Reports = z.infer<typeof reportsValidator>;

export const getInitiativeStepString = (value: number): string => {
    switch (value) {
        case 0:
            return 'conceptValidation';
        case 1:
            return 'pilot';
        case 2:
            return 'industrialization';
        case 3:
            return 'productionAtScale';
        default:
            return 'none';
    }
};
