import { PaginationState } from '@tanstack/react-table';

import { Pagination } from '../pagination';

type Props = {
    pagination: PaginationState;
    totalItems: number;
    paginationCallback: (pagination: PaginationState) => void;
    firstItemIndex: number;
    lastItemIndex: number;
    pageCount: number;
    paginationDetail?: boolean;
};

const TableFooter = ({
    pagination,
    totalItems,
    paginationCallback,
    firstItemIndex,
    lastItemIndex,
    pageCount,
    paginationDetail,
}: Props) => {
    return (
        <Pagination
            pagination={pagination}
            totalItems={totalItems}
            paginationCallback={paginationCallback}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex}
            pageCount={pageCount}
            paginationDetail={paginationDetail}
        />
    );
};

export { TableFooter };
