import { useQuery } from '@tanstack/react-query';

import { getDiscoveryCards } from './api';
import { discoveryCardListParser } from './domain';

import { retrieveFromCache, saveToCache } from '@shared/caching';

const queryKey = 'discoveryCards';

export const useDiscoveryCardsQuery = () =>
    useQuery(
        [queryKey],
        async () => {
            const response = await getDiscoveryCards();
            saveToCache(queryKey, response);
            return response;
        },
        { suspense: false, placeholderData: () => retrieveFromCache(queryKey, discoveryCardListParser.parse) }
    );
