import { useTranslation } from 'react-i18next';

import { namespaces } from '@shared/constants';
import { formatDateWithHour } from '@shared/formatting';
import { Contact } from '@shared/ui';

type CommentSectionProps = {
    name: string;
    text: string;
    date?: string;
    commentContext?: string;
    photo?: string;
};

function FundDetailComment({ name, text, date, commentContext, photo }: CommentSectionProps) {
    const { t } = useTranslation(namespaces.features.coInnovationFunds);

    const statusChangeKey =
        commentContext !== undefined ? commentContext || 'coInnovationFunds.fundsManagement.statuses.unknown' : '';
    const statusChangeText = ` ${t('coInnovationFunds.fundsManagement.changedTheStatusTo')} ${t(
        statusChangeKey
    ).toLowerCase()}`;
    const dateText = date !== undefined ? ` - ${formatDateWithHour(date)}` : '';
    const displayName = `${name}${statusChangeText}${dateText}`;

    return (
        <div className="mb-6" role="article" aria-label={`Comment by ${name}`}>
            <div className="mb-2 flex">
                <span className="mr-1">
                    <Contact name={displayName} photo={photo} />
                </span>
            </div>
            <p className="text-left text-sm font-light">{text}</p>
        </div>
    );
}

export default FundDetailComment;
