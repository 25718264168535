import { Suspense, useState } from 'react';

import { ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { anyFilter } from '../../shared';

import { DetailProject } from './domain';
import ProjectsKpis from './ProjectsKpis';
import { SingleProjectDetailedView } from './SingleProjectDetails';
import { useProjectsDetailsQuery } from './useProjectsDetailsQuery';

import { ReactComponent as LinkIcon } from '@assets/icons/link.svg';
import { useResizeMediaQuery } from '@features/layout';
import { defaultPageSize, namespaces } from '@shared/constants';
import { CustomColumn, DialogBody, DialogContainer, DialogHeader, MobileTable, Spinner, Table } from '@shared/ui';
import { usePagination, usePaginationTotals } from '@shared/ui/pagination/usePagination';

const ProjectsDetails = () => {
    const { t: coInnovationT } = useTranslation(namespaces.features.coInnovationFunds);
    const [selectedId, setSelectedId] = useState<string>();
    const [{ id: orderBy, desc }, setSorting] = useState<ColumnSort>({
        id: '',
        desc: false,
    });

    const { isMobile } = useResizeMediaQuery();

    const partnerTooltip = coInnovationT('coInnovationFunds.detail.tooltips.partner');
    const projectNameTooltip = coInnovationT('coInnovationFunds.detail.tooltips.projectName');
    const horizonTooltip = coInnovationT('coInnovationFunds.detail.tooltips.horizon');
    const itTeamTooltip = coInnovationT('coInnovationFunds.detail.tooltips.itTeam');
    const allocatedTooltip = coInnovationT('coInnovationFunds.detail.tooltips.allocated');
    const consumedTooltip = coInnovationT('coInnovationFunds.detail.tooltips.consumed');
    const statusTooltip = coInnovationT('coInnovationFunds.detail.tooltips.status');

    const columns: CustomColumn<DetailProject>[] = [
        {
            accessorKey: 'projectId',
            cellType: 'id',
            visible: false,
        },
        {
            accessorKey: 'partnerName',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.partner'),
            cellType: 'text',
            size: 10,
            sortable: true,
            tooltip: partnerTooltip,
        },
        {
            accessorKey: 'projectName',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.projectName'),
            cellType: 'text',
            size: 25,
            sortable: true,
            selectedColumnCallback: setSelectedId,
            tooltip: projectNameTooltip,
        },
        {
            accessorKey: 'horizonName',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.horizon'),
            cellType: 'text',
            size: 10,
            sortable: true,
            tooltip: horizonTooltip,
        },
        {
            accessorKey: 'teamName',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.nestleTeam'),
            cellType: 'text',
            size: 15,
            sortable: true,
            tooltip: itTeamTooltip,
        },
        {
            accessorKey: 'allocated',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.allocated'),
            cellType: 'currency',
            size: 10,
            sortable: true,
            tooltip: allocatedTooltip,
        },
        {
            accessorKey: 'consumed',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.consumed'),
            cellType: 'currency',
            size: 10,
            sortable: true,
            tooltip: consumedTooltip,
        },
        {
            accessorKey: 'phase',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.phase.title'),
            cellType: 'text',
            size: 10,
            sortable: true,
            tooltip: statusTooltip,
        },
        {
            accessorKey: 'startDate',
            header: coInnovationT('coInnovationFunds.detail.projectsDetails.startDate'),
            cellType: 'text',
            size: 15,
            sortable: true,
        },
    ];

    const {
        pagination: { pageIndex, pageSize },
        setPagination,
        firstItemIndex,
    } = usePagination();

    const {
        query: { data: detailProjects, isLoading },
        filter,
    } = useProjectsDetailsQuery({
        skip: pageIndex * pageSize,
        take: defaultPageSize,
        id: orderBy,
        desc: desc,
        paginationReset: () => {
            setPagination(prevState => ({ pageIndex: 0, pageSize: prevState.pageSize }));
        },
    });
    const { pageCount, lastItemIndex } = usePaginationTotals({
        totalItems: detailProjects?.totalCount || 0,
        pagination: { pageIndex, pageSize },
    });

    const selectedProject = detailProjects?.data.find(item => item.projectId === selectedId);
    const showDetailsDialog = selectedId !== undefined;
    const setShowDetailsDialog = (show: boolean): void => {
        if (show === false) {
            setSelectedId(undefined);
        }
    };
    const isFiltered = anyFilter(filter);

    return (
        <section className="h-full">
            {detailProjects?.aggregatedData && <ProjectsKpis aggregatedData={detailProjects.aggregatedData} />}

            {isMobile ? (
                <MobileTable
                    columns={columns}
                    data={detailProjects?.data ? detailProjects?.data : []}
                    pivotKey="projectName"
                    isLoading={isLoading}
                    idKey="projectId"
                    pagination={{ pageIndex, pageSize }}
                    paginationCallback={setPagination}
                    totalItems={detailProjects?.totalCount}
                    paginationDetail={true}
                    useDefaultRowHandler={true}
                    firstItemIndex={firstItemIndex}
                    lastItemIndex={lastItemIndex}
                    pageCount={pageCount}
                    setSelectedRowId={setSelectedId}
                />
            ) : (
                <>
                    <Table
                        columns={columns}
                        totalItems={detailProjects?.totalCount}
                        data={detailProjects?.data}
                        caption={coInnovationT('coInnovationFunds.detail.dashboard.tabName')}
                        pagination={{ pageIndex, pageSize }}
                        paginationCallback={setPagination}
                        sorting={{ id: orderBy, desc }}
                        sortingCallback={setSorting}
                        isLoading={isLoading}
                        isFiltered={isFiltered}
                        expectedBodyHeightClass="h-[432px]"
                        firstItemIndex={firstItemIndex}
                        lastItemIndex={lastItemIndex}
                        pageCount={pageCount}
                    />
                </>
            )}

            {showDetailsDialog ? (
                <DialogContainer
                    setShowDialog={setShowDetailsDialog}
                    dialogPosition={'right'}
                    showDialog={showDetailsDialog}
                >
                    <DialogHeader onCloseButtonClick={() => setShowDetailsDialog(false)}>
                        <div> {selectedProject ? selectedProject.projectName : ''}</div>
                        <div className="flex items-center text-sm font-light">
                            <LinkIcon />
                            <a
                                className="margin px-1 text-blue-800 underline"
                                target="_blank"
                                href={`https://nestle.service-now.com/nav_to.do?uri=pm_project.do?sys_id=${
                                    selectedProject ? `${selectedProject.serviceNowSysId}` : ''
                                }`}
                                rel="noreferrer"
                            >
                                {coInnovationT('coInnovationFunds.detail.projectsDetails.serviceNow')}
                            </a>
                        </div>
                    </DialogHeader>

                    <DialogBody>
                        <Suspense fallback={<Spinner />}>
                            {selectedId ? <SingleProjectDetailedView id={selectedId} /> : null}
                        </Suspense>
                    </DialogBody>
                </DialogContainer>
            ) : null}
        </section>
    );
};

export { ProjectsDetails };
