// FundDetailFooter.js or FundDetailFooter.tsx if you're using TypeScript

import { fundStatuses } from './constants';

import { HasPermission } from '@features/authorization/HasPermission';
import { permissions } from '@features/authorization/permissions';
import { ApproveButton } from '@shared/ui/button/ApproveButton';
import { CancelButton } from '@shared/ui/button/CancelButton';
import { ChangeRequestButton } from '@shared/ui/button/ChangeRequestButton';
import { RejectButton } from '@shared/ui/button/RejectButton';

enum ActionType {
    Cancel = 'cancel',
    Approve = 'approve',
    Reject = 'reject',
    ApproveCR = 'approveCR',
    RejectCR = 'rejectCR',
    CancelCR = 'cancelCR',
    ChangeRequest = 'changeRequest',
}

type FundDetailFooterProps = {
    statusId?: number;
    handleActionClick: (actionType: ActionType) => void;
};

export const FundDetailFooter = ({ statusId, handleActionClick }: FundDetailFooterProps) => {
    if (statusId === undefined) {
        return null;
    }

    const canCancelFundsPermissions = [permissions.coInnovationFunds.fundsManagement.cancel];
    const canValidateFundsPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

    const canChangeRequestPermissions = [
        permissions.coInnovationFunds.allRequests.create,
        permissions.coInnovationFunds.partnerRequests.create,
    ];

    const canValidateChangeRequestPermissions = [permissions.coInnovationFunds.fundsManagement.validation];

    return (
        <footer className="mt-3 justify-start space-x-1  md:grid md:grid-cols-4" aria-label="Fund Details Actions">
            <div className="flex md:col-start-4 md:space-x-1">
                {[fundStatuses.pending].includes(statusId) && (
                    <>
                        <HasPermission neededPermissions={canCancelFundsPermissions}>
                            <CancelButton onClickAction={() => handleActionClick(ActionType.Cancel)} />
                        </HasPermission>

                        <HasPermission neededPermissions={canValidateFundsPermissions}>
                            <RejectButton onClickAction={() => handleActionClick(ActionType.Reject)} />
                        </HasPermission>
                        <HasPermission neededPermissions={canValidateFundsPermissions}>
                            <ApproveButton onClickAction={() => handleActionClick(ActionType.Approve)} />
                        </HasPermission>
                    </>
                )}
                {[fundStatuses.changeRequestPending].includes(statusId) && (
                    <>
                        <HasPermission neededPermissions={canCancelFundsPermissions}>
                            <CancelButton onClickAction={() => handleActionClick(ActionType.CancelCR)} />
                        </HasPermission>
                        <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                            <RejectButton onClickAction={() => handleActionClick(ActionType.RejectCR)} />
                        </HasPermission>
                        <HasPermission neededPermissions={canValidateChangeRequestPermissions}>
                            <ApproveButton onClickAction={() => handleActionClick(ActionType.ApproveCR)} />
                        </HasPermission>
                    </>
                )}
                {[fundStatuses.approved].includes(statusId) && (
                    <HasPermission neededPermissions={canChangeRequestPermissions}>
                        <ChangeRequestButton onClickAction={() => handleActionClick(ActionType.ChangeRequest)} />
                    </HasPermission>
                )}
            </div>
        </footer>
    );
};
