import { z } from 'zod';

export const guidelineParser = z.object({
    id: z.number(),
    title: z.string(),
    content: z.string(),
});

export const guidelineListParser = z.array(guidelineParser);

export type GuidelineData = z.infer<typeof guidelineParser>;
