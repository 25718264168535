import { formatNumber } from './formatNumber';

export const formatProvidedValue = (value: string, valueType: number) =>
    value
        ? value
              .split(',')
              .map((valuePart, index) => {
                  if (index === 0) {
                      return formatNumber(+valuePart, {
                          simplify: false,
                          unitText: '',
                          valueType: valueType,
                          minimumFractionDigits: 0,
                      });
                  }

                  return valuePart;
              })
              .join(',')
        : '';
